<template>
  <el-dialog
    width="460px"
    center
    title="作业任务设置"
    :visible.sync="dialogVisible"
    custom-class="edit-modal"
  >
    <div class="row">
      <div class="row-title">作业名称：</div>
      <div class="flex">
        <el-input v-model="name" class="name-input w-270">
          <img slot="suffix" class="edit-icon" src="../assets/edit3.png" alt="icon">
        </el-input>
      </div>
    </div>
    <div class="row">
      <div class="row-title">截止时间：</div>
      <div class="flex">
        <el-date-picker
          v-model="dateEnd"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
          prefix-icon=" "
          class="data-date-picker w-270"
          :clearable="false"></el-date-picker>
        <img class="date-icon" src="../assets/spread-2.png" alt="icon">
      </div>
    </div>
    <div class="remark">
      {{ remark }}
    </div>
    <div class="footer">
      <el-button plain class="btn close" @click="handleClose">取消</el-button>
      <el-button class="btn confirm ml-28" type="primary" @click="confirm">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'EditWorkSet',
  data() {
    return {
      dialogVisible: false,
      teacherId: '',
      itemList: [],
      dateStart: '',
      dateEnd: '',
      name: '',
      remark: '若修改了作业任务的截止时间，其包含的所有子作业的截止时间都会被同步修改。',
    }
  },
  methods: {
    // 确定按钮
    confirm() {
      let {dateEnd} = this
      if (new Date(dateEnd).getTime() <= new Date(this.dateStart).getTime()) {
        this.$message({
          type: 'error',
          message: '结束时间应大于开始时间'
        })
        return
      }
      dateEnd = this.common.binarySystem(dateEnd)
      dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(dateEnd))
      const dataParams = {
        id: this.id,
        dateEnd,
        name: this.name,
        teacherId: this.teacherId,
        itemList: this.itemList.map(id => {
          return {
            id: id,
            dateEnd: dateEnd
          }
        })
      }
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.updateBagItem,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataParams
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.dialogVisible = false
          this.$message({
            type: 'success',
            message: '修改成功'
          })
          this.$emit('refreshPage')
        }
      }).catch(() => {
        this.loading = false
      })
    },
    dialogVisibleChange(item) {
      this.dialogVisible = !this.dialogVisible
      if (this.dialogVisible) {
        this.id = item.id || item.bagId
        this.teacherId = item.teacherId
        this.itemList = item.itemIds ? item.itemIds.split(',') : []
        this.dateStart = item.dateStart
        this.dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm', new Date(item.dateEnd))
        this.name = item.name
      }
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-modal {
  //border-radius: 16px;
  .el-dialog__body {
    padding: 14px 40px;
  }
}
::v-deep .el-dialog{
  &.edit-modal{
    border-radius: 16px;
    .el-dialog__header{
      padding: 25px 28px;
      font-size: 20px;
      color: #333333;
      font-weight: bold;
      border-bottom: 1px solid #E5E5E5;
      .el-dialog__headerbtn{
        width: 50px;
        height: 50px;
        top: 14px;
        right: 14px;
        font-size: 22px;
        border-radius: 25px;
        color: black;
        .el-icon-close{
          color: black;
        }
        &:hover{
          background-color: #F6F6F6;
          .el-icon-close{
            color: black;
          }
        }
      }
    }
    .el-dialog__body{
      padding: 10px 40px 28px;
    }
    .el-dialog__footer{
      padding: 0;
    }
  }
}

.row {
  @include flex(space-between);
  height: 50px;
  border-bottom: 1px solid #F1F1F1;
  font-size: 17px;
  color: #333333;
  .row-title{
    font-size: 17px;
    color: #333333;
    line-height: 24px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 6px;
    height: 11px;
    margin-left: 14px;
  }
  .date-icon {
    width: 11px;
    height: 6px;
    margin-left: -20px;
    margin-right: 10px;
    z-index: 1;
  }
  ::v-deep .el-input{
   &.name-input{
     .el-input__inner{
       height: 40px;
       line-height: 40px;
       border: none;
       background-color: #FFFFFF;
       &:focus{
         background-color: #F6F6F6;
       }
     }
     .edit-icon{
       width: 18px;
       height: 18px;
       //top: 12px;
     }
     .el-input__suffix{
       line-height: 40px;
       right: 10px;
       top: 2px;
     }
     &:focus{
       .el-input__inner{
         border: none;
         background-color: #F6F6F6;
       }
     }
   }
  }
  ::v-deep .el-date-editor{
    &.data-date-picker{
      .el-input__inner{
        height: 40px;
        line-height: 40px;
        padding-left: 15px;
      }
    }
  }
}
.remark{
  color: #FF3C30;
  font-size: 17px;
  line-height: 24px;
  margin-top: 14px;
}

.footer {
  @include flex;

  .btn {
    width: 160px;
    height: 40px;
    margin-top: 28px;
    font-size: 15px;
    &.close{
      color: #666666;
      border-color: #C8C8C8;
      border-radius: 4px;
      &:hover{
        color: #666666;
        background-color: #F6F6F6;
        border-color: #C8C8C8;
      }
    }
    &.confirm{
      background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
      &:hover{
        background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
      }
    }
  }
}
.w-270{
  width: 270px;
}
</style>
