<template>
  <article v-loading="loading">
    <RouterNav :name="name"></RouterNav>
    <header class="header">
      <div class="top">
        <div class="top-left">
          <div class="top-left-name">{{ bagWorkInfo.name }}</div>
          <div class="top-left-status" :style="{ color: statusType[bagWorkInfo.status] ? statusType[bagWorkInfo.status].color : '' }">【{{ statusType[bagWorkInfo.status] ? statusType[bagWorkInfo.status].name : ''}}】</div>
        </div>
        <div class="top-right">
          <div class="export-btn mr-14" @click="editWork(bagWorkInfo)">
            <div>任务设置</div>
          </div>
          <div class="export-btn" @click="exportBagExcel">
            <div class="icon"></div>
            <div>导出当前成绩</div>
          </div>
        </div>
      </div>
      <div class="top-info">
        <div class="top-info-date">
          结束时间：{{ formatDate(bagWorkInfo.dateEnd, 'YYYY-MM-DD HH:mm') }}
        </div>
        <div class="top-info-value">
          <span class="top-info-content"><span style="color: #1DC060">{{ bagWorkInfo.finishedStudents ? bagWorkInfo.finishedStudents.length : 0 }}</span>人已完成，<span style="color: #FF3C30">{{ bagWorkInfo.unfinishedStudents ? bagWorkInfo.unfinishedStudents.length : 0 }}</span>人待完成</span>
          <span class="view-list" @click="viewList">查看名单</span>
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <div v-if="bagWorkInfo.status === '0'" class="mb-10">
            <span class="date">开始时间：{{ dateStart | trimT }}</span>
          </div>
          <div v-if="['1', '2'].includes(bagWorkInfo.status)">
            <span class="date">结束时间：{{ dateEnd | trimT }}</span>
            <!--<span>得分率：{{ scoreRate }}%</span>-->
          </div>
        </div>
        <!--<div>-->
        <!--<span>当前作业还有</span>-->
        <!--<span class="num">12</span>-->
        <!--<span>人未完成</span>-->
        <!--<span class="check-btn">查看</span>-->
        <!--</div>-->
      </div>
      <div class="work-info mt-20">
        <el-row :gutter="28" style="width: 100%">
          <el-col :span="8">
            <div class="work-info-item" style="background-color: #1485E3">
              <div class="work-info-item-title">
                <span>班级</span>
              </div>
              <div class="work-info-item-value">
                {{ bagWorkInfo.clazzName }}
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="work-info-item" style="background-color: #FF9300">
              <div class="work-info-item-title">
                <span>作业率</span>
                <el-tooltip
                  class="item"
                  effect="light"
                  placement="bottom-end"
                  content="当前作业任务的整体参与情况，为所有子作业的作业率平均值"
                  popper-class="title-popper"
                >
                  <img src="@/assets/tooltip.png" alt="top">
                </el-tooltip>
              </div>
              <div class="work-info-item-value">
                {{ bagWorkInfo.operatingRate ? bagWorkInfo.operatingRate.toFixed(1) : 0 }}%
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="work-info-item" style="background-color: #03A947">
              <div class="work-info-item-title">
                <span>得分率</span>
                <el-tooltip
                  class="item"
                  effect="light"
                  placement="bottom-end"
                  content="当前作业任务的整体得分情况，为所有子作业的得分率平均值"
                  popper-class="title-popper"
                >
                  <img src="@/assets/tooltip.png" alt="top">
                </el-tooltip>
              </div>
              <div class="work-info-item-value">
                {{ bagWorkInfo.scoreRate ? bagWorkInfo.scoreRate.toFixed(1) : 0 }}%
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </header>
    <section class="content">
      <!-- 子作业列表 -->
      <transition-group name="list-complete" tag="div">
        <div class="list" v-for="(item, index) in list" :key="item.itemId" @click="workDetail(index)">
          <div class="list-content">
            <div class="info">
              <div class="name" v-html="item.name"></div>
              <div class="module" v-html="item.type"></div>
            </div>
            <div class="clazz">{{ item.clazzName }}</div>
            <div class="num-column">
              <div class="mb-10">{{ item.score ? item.score.toFixed(1) : 0 }}/{{ item.totalScore }}分</div>
              <div>{{ item.operatingCount }}/{{ item.studentCount }}完成</div>
            </div>
            <div class="type-column">
              <div
                  :class="['mb-10', item.status == 0 ? 'theme-color' : item.status == 1 ? 'col-1DC060' : 'col-999']">
                {{ item.status == 0 ? '未开始' : item.status == 1 ? '进行中' : '已结束' }}
              </div>
              <div class="time">
                <span>{{ item.showDateStart }}</span>
                <span class="text">至</span>
                <span>{{ item.showDateEnd }}</span>
              </div>
            </div>
            <div class="btn-column">
              <div class="btn btn-1" @click.stop="exportExcel(item.itemId)">导</div>
              <div class="btn btn-2" @click.stop="setDate(index)">修</div>
              <div class="btn btn-3" @click.stop="delWork(index)">删</div>
            </div>
          </div>
        </div>
      </transition-group>
    </section>

    <setDateDialog ref="setDateDialog" @refreshPage="refreshPage"></setDateDialog>
    <EditWorkSet ref="EditWorkSet" @refreshPage="refreshPage"></EditWorkSet>
    <!-- 查看名单 -->
    <el-dialog class="info-dialog" width="1100px" top="0" title="查看未完成名单" :visible.sync="visible">
      <div class="info-dialog-body" ref="unfinishedRef">
        <div class="info-item-container">
          <div class="info-item">
            <div class="info-item-title">作业名称：</div>
            <div class="info-item-contenet">
              {{ bagWorkInfo.name }}
            </div>
          </div>
          <div class="info-item">
            <div class="info-item-title">子作业数量：</div>
            <div class="info-item-contenet">
              {{ bagWorkInfo.itemNum }}
            </div>
          </div>
          <div class="info-item">
            <div class="info-item-title">作业班级：</div>
            <div class="info-item-contenet">
              {{ bagWorkInfo.clazzName }}
            </div>
          </div>
          <div class="info-item">
            <div class="info-item-title">截止时间：</div>
            <div class="info-item-contenet">
              {{ bagWorkInfo.dateEnd }}（{{ formatDay(bagWorkInfo.dateEnd) }}）
            </div>
          </div>
          <div v-if="bagWorkInfo.unfinishContent" class="info-item">
            <div class="info-item-title">未完成名单如下：</div>
            <div class="info-item-contenet">
              <span v-text="bagWorkInfo.unfinishContent" />
            </div>
          </div>
          <div v-if="bagWorkInfo.flunkContent" class="info-item">
            <div class="info-item-title">不及格名单如下：</div>
            <div class="info-item-contenet">
              <span v-text="bagWorkInfo.flunkContent" />
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <el-button class="copy-btn btn-primary mr-28" type="primary" @click="contentCopy( '复制未完成名单')">复制未完成名单</el-button>
        <el-button class="copy-btn empty" @click="batchUrge">一键催交</el-button>
      </div>
    </el-dialog>
  </article>
</template>

<script>
import setDateDialog from '@/components/setDateDialog.vue'
import RouterNav from '@/components/RouterNav.vue'
import EditWorkSet  from '@/components/EditWorkSet'
import { bagDetail, sentHomeworkBagNotice } from '@/api/homework.js'
import dayjs from 'dayjs'

export default {
  name: 'bagWorkList',
  components: {
    setDateDialog,
    RouterNav,
    EditWorkSet
  },
  data() {
    return {
      loading: false,
      scoreRate: '',
      name: '',
      operatingRate: '',
      dateStart: '',
      dateEnd: '',
      list: [],
      dialogVisible: false,
      dialogDate: '',
      index: '',
      statusType: {
        '0': {
          name: '未开始',
          color: '#309AF2'
        },
        '1': {
          name: '进行中',
          color: '#1DC060'
        },
        '2': {
          name: '已结束',
          color: '#999999'
        }
      },
      bagWorkInfo: {},
      visible: false
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  created() {
    this.id = this.$route.query.id
    this.name = this.$route.query.name
    this.bagDetail()
  },
  methods: {
    refreshPage() {
      this.bagDetail()
    },
    setDate(index) {
      const {list} = this
      this.$refs.setDateDialog.dialogVisibleChange(list[index])
    },
    bagDetail() {
      this.loading = true
      bagDetail({ bagId: this.id }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          const {data} = response
          const { unfinishedStudents = [], flunkStudents = [], homeworkItemList = [] } = data
          this.bagWorkInfo = {}
          this.bagWorkInfo = data
          this.bagWorkInfo.unfinishContent = unfinishedStudents.map(el => `${ el.realName }（${ el.unfinishedCount || 0 }个未完成）`).join('、')
          this.bagWorkInfo.flunkContent = flunkStudents.map(el => `${ el.realName }（${ el.flunkCount || 0 }个不及格）`).join('、')
          this.dateStart = data.dateStart
          this.dateEnd = data.dateEnd
          this.scoreRate = data.scoreRate.toFixed(2)
          this.operatingRate = data.operatingRate.toFixed(2)
          for (let i = 0; i < homeworkItemList.length; i++) {
            homeworkItemList[i].showDateStart = this.common.formatDate('MM-dd hh:mm', new Date(homeworkItemList[i].dateStart))
            homeworkItemList[i].showDateEnd = this.common.formatDate('MM-dd hh:mm', new Date(homeworkItemList[i].dateEnd))
          }
          this.list = homeworkItemList
        }
      })
      .finally(() => {
        this.loading = false
      })
    },
    // 编辑任务包
    editWork(item) {
      this.$refs.EditWorkSet.dialogVisibleChange(item)
    },
    // 导出成绩表
    exportBagExcel() {
      const that = this
      const h = this.$createElement
      let checked = false
      const random = Math.random()
      const provinceId = sessionStorage.getItem('schoolProvinceId')
      const section = sessionStorage.getItem('schoolSection')
      this.$confirm( '', '提示',{
        message: h('div', null, [
          h('p', null, '确认导出成绩吗？'),
          (provinceId === '440000' && section === '2' ? h('ElCheckbox', {
            style: 'position: absolute;left: 6px;bottom: -30px;',
            key: random,
            props: {
              checked: checked
            },
            on: {
              change(val) {
                checked = val
              }
            }
          }, '导出转换为20分制') : ''),
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const convertScore = checked ? 1 : 0
        checked = false
        that.$axios({
          method: 'post',
          url: that.$urls.exportBagScope,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            bagId: that.id,
            convertScore
          }
        }).then((response) => {
          if (response.state === '11') {
            that.$message.success('下载任务已提交，请前往下载中心查看进度')
          }
        }).catch(() => {
        })
        // window.open(`${that.$urls.exportBagScore}?bagId=${that.id}`)
      })
      .catch((err) => {
        checked = false
        throw err
      })
    },
    exportExcel(itemId) {
      const that = this
      const h = this.$createElement
      let checked = false
      const random = Math.random()
      const provinceId = sessionStorage.getItem('schoolProvinceId')
      const section = sessionStorage.getItem('schoolSection')
      this.$confirm('确认导出成绩吗？', '提示', {
        message: h('div', null, [
          h('p', null, '确认导出成绩吗？'),
          (provinceId === '440000' && section === '2' ? h('ElCheckbox', {
            style: 'position: absolute;left: 6px;bottom: -30px;',
            key: random,
            props: {
              checked: checked
            },
            on: {
              change(val) {
                checked = val
              }
            }
          }, '导出转换为20分制') : ''),
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const convertScore = checked ? 1 : 0
        checked = false
        that.$axios({
          method: 'post',
          url: that.$urls.exportItemScope,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            itemId: itemId,
            convertScore
          }
        }).then((response) => {
          if (response.state === '11') {
            that.$message.success('下载任务已提交，请前往下载中心查看进度')
          }
        }).catch(() => {
        })
      })
      .catch((err) => {
        checked = false
        throw err
      })
    },
    delWork(index) {
      const that = this
      this.$confirm('删除后学生将无法收到该作业？', '删除作业', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.confirmDel(index)
      }).catch((err) => {
        throw err
      })
    },
    // 确认删除子作业
    confirmDel(index) {
      const {list} = this
      const {itemId} = list[index]
      this.$axios({
        method: 'post',
        url: this.$urls.homeworkDelUrl,
        data: { id: itemId }
      }).then((response) => {
        if (response.state === '11') {
          this.list.splice(index, 1)
          if (this.list.length === 0) {
            this.$store.commit('includeDel', 'checkWork')
            this.$router.back()
          } else {
            this.bagDetail()
          }
        }
      })
    },
    workDetail(index) {
      const {list} = this
      const {itemId, name, clazzId, passageType, flag, bagId} = list[index]
      this.$router.push({
        name: 'workDetail',
        query: {
          itemId: itemId,
          name,
          clazzId,
          passageType,
          bagId,
          flag
        }
      })
    },
    viewList() {
      this.visible = true
    },
    contentCopy (prefix = '') {
      const text = this.$refs.unfinishedRef.innerText
      const input = document.createElement('textarea')
      document.body.appendChild(input)
      input.value = text
      input.select()
      const flag = document.execCommand('Copy')
      const content = `${prefix}${flag ? '复制成功' : '复制失败'}`
      this.$message.success(content)
      document.body.removeChild(input)
    },
    batchUrge() {
      const params = {
        teacherId: this.userInfo.teacherId,
        teacherName: this.userInfo.teacherName,
        studentIds: this.bagWorkInfo.unfinishedStudents.map(el => el.id),
        bagId: this.id,
        bagName: this.bagWorkInfo.name,
        noticeCategory: 107
      }
      sentHomeworkBagNotice(params).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.dialogVisible = false
          this.$message({
            message: '批量催交成功',
            type: 'success'
          })
        }
      })
      .catch(() => {
        this.loading = false
      })
    },
    formatDate(time, format = 'YYYY-MM-DD') {
      return dayjs(time).format(format)
    },
    formatDay(time) {
      const dayMap = {
        0: '周日',
        1: '周一',
        2: '周二',
        3: '周三',
        4: '周四',
        5: '周五',
        6: '周六'
      }
      return dayMap[dayjs(time).day()]
    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  background-color: #fff;
  height: 40px;
  line-height: 40px;
  padding-left: 28px;
  @include font(14px, #999999);
  margin-bottom: 1px;

  .link {
    color: #999999;

    &:hover {
      color: #309AF2;
      text-decoration: underline;
    }
  }

  .icon {
    display: inline-block;
    margin: 0 6px;
  }
}

.header {
  background-color: #fff;
  padding: 20px 28px;
  margin-bottom: 10px;

  .top {
    @include flex(space-between);
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    margin-bottom: 4px;
    .top-left{
      @include flex(space-between, center);
      .top-left-status{
        font-size: 15px;
        line-height: 21px;
      }
    }
    .top-right{
      @include flex(space-between, center);
    }

    .export-btn {
      @include flex;
      height: 34px;
      padding: 0 20px;
      border: 1px solid #E5E5E5;
      border-radius: 17px;
      font-size: 15px;
      font-weight: normal;
      cursor: pointer;

      .icon {
        @include icon(url("../../../assets/excel.png"), 26px, 26px);
        margin-right: 6px;
      }

      &:hover {
        background-color: #F6F6F6;
      }
    }
  }
  .top-info{
    margin-top: 10px;
    @include flex(space-between);
    .top-info-date{
      font-weight: 400;
      font-size: 15px;
      color: #999999;
      line-height: 21px;
      text-align: left;
    }
    .top-info-value{
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      text-align: left;
      .view-list{
        margin-left: 14px;
        font-weight: 400;
        font-size: 14px;
        color: #309AF2;
        line-height: 20px;
        text-align: left;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }

  .bottom {
    @include flex(space-between, flex-end);
    font-size: 15px;

    .date {
      display: inline-block;
      color: #999999;
      margin-right: 66px;
    }

    .num {
      display: inline-block;
      color: #FF3C30;
      margin: 0 4px;
    }

    .check-btn {
      display: inline-block;
      color: #309AF2;
      text-decoration: underline;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .work-info{
    width: 100%;
    @include flex(space-between, center);
    .work-info-item{
      width: 330px;
      height: 100px;
      color: #FFFFFF;
      border-radius: 8px;
      padding: 15px 15px 15px 28px;
      .work-info-item-title{
        font-size: 17px;
        line-height: 24px;
        @include flex(space-between, center);
      }
      .work-info-item-value{
        font-size: 30px;
        line-height: 42px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.content {
  background-color: #fff;
  height: calc(100vh - 265px);
  padding: 10px 28px;
  overflow: auto;

  .list {
    padding: 0 28px;
    cursor: pointer;
    transition: .5s;

    &:hover {
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .08);
    }

    .list-content {
      @include flex;
      height: 100px;
      border-bottom: 1px solid #F1F1F1;

      .info {
        flex-grow: 1;
        overflow: hidden;

        .name {
          margin-bottom: 10px;
          font-size: 17px;
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .bag-name {
          background-color: #EAF4FD;
          height: 24px;
          line-height: 24px;
          padding: 0 10px;
          border-radius: 12px;
          @include font(14px, #309AF2, center);
          white-space: nowrap;
        }

        .type {
          @include font(13px, #999);
          margin-top: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .clazz {
        flex-shrink: 0;
        width: 130px;
        font-size: 17px;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .num-column {
        flex-shrink: 0;
        width: 130px;
        font-size: 15px;
        text-align: center;
      }

      .type-column {
        flex-shrink: 0;
        width: 280px;
        font-size: 15px;
        text-align: center;

        .time {
          font-size: 14px;

          .text {
            margin: 0 14px;
          }
        }
      }

      .btn-column {
        flex-shrink: 0;
        @include flex;
        width: 136px;

        .btn {
          width: 30px;
          height: 30px;
          line-height: 30px;
          border: 1px solid #1DC060;
          border-radius: 4px;
          margin-right: 8px;
          @include font(15px, #1DC060, center);
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }
        }

        .btn-1:hover {
          color: #FFFFFF;
          background-color: #1DC060;
        }

        .btn-2 {
          color: #309AF2;
          border-color: #309AF2;

          &:hover {
            color: #FFFFFF;
            background-color: #309AF2;
          }
        }

        .btn-3 {
          color: #FF3C30;
          border-color: #FF3C30;

          &:hover {
            color: #FFFFFF;
            background-color: #FF3C30;
          }
        }
      }
    }
  }
}
.mb-10 {
  margin-bottom: 10px;
}
</style>
<style lang="scss">
.title-popper{
  &.el-tooltip__popper[x-placement^="bottom-end"]{
    width: 250px;
    border: #FFFFFF !important;
    font-size: 15px;
    line-height: 21px;
    color: #333333;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    .popper__arrow{
      border-bottom-color: #FFFFFF;
      &:after{
        border-bottom-color: #FFFFFF;
      }
    }
  }
}
.info-dialog{
  display: flex;
  align-items: center;
  justify-content: center;
  .el-dialog{
    margin: 0 auto;
    //max-height:
    max-height: calc(100vh - 80px);
    border-radius: 16px;
  }
  .el-dialog__header {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    border-bottom: 1px solid #E5E5E5;
    box-sizing: border-box;
    text-align: center;
    padding: 25px 0;
    .el-dialog__title{
      font-weight: bold;
      font-size: 20px;
      color: #333333;
      line-height: 28px;
    }
    .el-dialog__headerbtn{
      width: 50px;
      height: 50px;
      top: 14px;
      right: 14px;
      font-size: 22px;
      border-radius: 25px;
      //color: black;
      .el-icon-close{
        color: black;
      }
      &:hover{
        background-color: #F6F6F6;
        .el-icon-close{
          color: black;
        }
      }
    }
  }
  .el-dialog__body {
    padding: 0;
  }
  .foot {
    text-align: center;
    padding-bottom: 20px;
    .copy-btn {
      border-radius: 8px;
      width: 150px;
      height: 40px;
      font-size: 15px;
      &.btn-primary{
        background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
        &:hover {
          background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
        }
      }
      &.empty{
        border-color: #309AF2;
        color: #309AF2;
        &:hover{
          background: #F6F6F6;
        }
      }
    }
  }
  .info-dialog-body{
    //max-height: calc(100vh - 80px - 80px - 20px - 20px - 60px);
    padding: 20px 0;
    .info-item-container{
      max-height: calc(100vh - 80px - 80px - 40px - 60px - 40px);
      overflow-y: auto;
      padding: 0 40px;
      .info-item{
        &+.info-item{
          margin-top: 20px;
        }
        .info-item-title{
          font-weight: 600;
          font-size: 18px;
          color: #333333;
          line-height: 25px;
          text-align: left;
          margin-bottom: 4px;
        }
        .info-item-contenet{
          font-weight: 400;
          font-size: 18px;
          color: #999999;
          line-height: 25px;
          text-align: left;
          .textarea-Content {
            background-color: #F6F6F6;
            //width: 544px;
            width: 544px;
            height: fit-content;
            //min-height: 30vh;
            //max-height: 50vh;
            border: none;
            border-radius: 16px;
            padding: 20px;
            //margin: 0 28px 28px;
            font-size: 17px;
            line-height: 24px;
            //outline: 0 none;
            resize: none;
          }
        }
      }
    }
  }
}
</style>
