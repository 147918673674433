<template>
  <el-dialog
      width="460px"
      center
      title="修改作业"
      :visible.sync="dialogVisible"
      custom-class="set-modal"
  >
    <div class="row">
      <div>截止时间：</div>
      <div class="flex">
        <el-date-picker
          v-model="dateEnd"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
          prefix-icon=" "
          class="data-date-picker w-270"
          :clearable="false"></el-date-picker>
        <!--<img class="icon" src="../assets/nav-icon.png" alt="icon">-->
        <img class="date-icon" src="../assets/spread-2.png" alt="icon">
      </div>
    </div>
    <div class="row">
      <div>得分要求：</div>
      <div class="finish-rate w-270">
        <span>{{ minFinishRate ? `${minFinishRate}%` : '不限制' }}</span>
        <span style="color: #999999; font-size: 16px">（最低得分率要求不可修改）</span>
      </div>
    </div>
    <div class="row">
      <div>作答次数：</div>
      <div class="flex">
        <el-select class="xy-select w-270" v-model="answerTimes">
          <el-option :value="1" label="允许重复作答"></el-option>
          <el-option :value="2" label="只能做一次"></el-option>
        </el-select>
      </div>
    </div>
    <div class="row">
      <div>过期补交：</div>
      <div class="flex">
        <el-select class="xy-select w-270" v-model="expireRedo">
          <el-option :value="2" label="不允许过期补交"></el-option>
          <el-option :value="1" label="允许过期补交"></el-option>
        </el-select>
      </div>
    </div>
    <div class="row">
      <div>公布答案：</div>
      <div class="flex">
        <el-select class="xy-select w-270" v-model="toPublic">
          <el-option :value="1" label="完成后立即公布"></el-option>
          <el-option :value="2" label="自定义公布时间"></el-option>
          <el-option :value="3" label="作业截止后公布"></el-option>
        </el-select>
      </div>
    </div>
    <div class="row" v-if="toPublic === 2">
      <div>自定义时间：</div>
      <div class="flex">
        <el-date-picker
          v-model="publicTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
          :clearable="false"
          prefix-icon=" "
          class="data-date-picker w-270"
        ></el-date-picker>
      </div>
    </div>
    <div class="footer">
      <el-button class="btn" type="primary" @click="confirm">确定</el-button>
      <el-button plain class="btn" @click="handleClose">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'setDateDialog',
  data() {
    return {
      dialogVisible: false,
      dateTime: '',
      minFinishRate: null,
      answerTimes: 1,
      expireRedo: 2,
      toPublic: 1,
      publicTime: '',
      dateStart: '',
      dateEnd: ''
    }
  },
  watch: {
    toPublic: {
      handler(val) {
        if (val === 2 && !this.publicTime) {
          this.publicTime = this.common.formatDate('yyyy-MM-dd hh:mm', new Date())
        }
      }
    }
  },
  methods: {
    confirm() {
      let {dateEnd} = this
      if (new Date(dateEnd).getTime() <= new Date(this.dateStart).getTime()) {
        this.$message({
          type: 'error',
          message: '截止时间应大于开始时间'
        })
        return
      }
      dateEnd = this.common.binarySystem(dateEnd)
      dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(dateEnd))
      const {toPublic} = this
      const dataParams = {
        itemId: this.id,
        dateEnd,
        answerTimes: this.answerTimes,
        expireRedo: this.expireRedo,
        toPublic
      }
      if (toPublic === 2) {
        dataParams.publicTime = this.common.formatDate('yyyy-MM-dd hh:mm:ss', new Date(this.publicTime))
      }
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.homeworkUpdateItem,
        data: dataParams
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          this.dialogVisible = false
          this.$message({
            type: 'success',
            message: '修改成功'
          })
          this.$emit('refreshPage')
        }
      }).catch(() => {
        this.loading = false
      })
    },
    dialogVisibleChange(item) {
      this.dialogVisible = !this.dialogVisible
      if (this.dialogVisible) {
        this.id = item.itemId
        this.answerTimes = item.answerTimes
        this.minFinishRate = item.minFinishRate
        this.expireRedo = item.expireRedo
        this.toPublic = item.toPublic
        if (this.toPublic === 2) {
          this.publicTime = this.common.formatDate('yyyy-MM-dd hh:mm', new Date(item.publicTime))
        }
        this.dateStart = item.dateStart
        this.dateEnd = this.common.formatDate('yyyy-MM-dd hh:mm', new Date(item.dateEnd))
      }
    },
    handleClose() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss">
//.set-modal .el-dialog__body {
//  padding: 14px 28px;
//}

.xy-select {
  .el-input__inner {
    height: 40px;
    line-height: 40px;
  }
}
</style>
<style lang="scss" scoped>
.set-modal {
  border-radius: 16px;
}
::v-deep .el-dialog{
  &.set-modal{
    border-radius: 16px;
    .el-dialog__header{
      padding: 25px 28px;
      font-size: 20px;
      color: #333333;
      font-weight: bold;
      border-bottom: 1px solid #E5E5E5;
      .el-dialog__headerbtn{
        width: 50px;
        height: 50px;
        top: 14px;
        right: 14px;
        font-size: 22px;
        border-radius: 25px;
        color: black;
        .el-icon-close{
          color: black;
        }
        &:hover{
          background-color: #F6F6F6;
          .el-icon-close{
            color: black;
          }
        }
      }
    }
    .el-dialog__body{
      padding: 10px 40px 28px;
    }
    .el-dialog__footer{
      padding: 0;
    }
  }
}

.row {
  @include flex(space-between);
  height: 50px;
  font-size: 17px;
  color: #333333;

  .flex {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 6px;
    height: 11px;
    margin-left: 14px;
  }
  .date-icon {
    width: 11px;
    height: 6px;
    margin-left: -20px;
    margin-right: 10px;
    z-index: 1;
  }
  .finish-rate{
    width: 270px;
    padding: 10px 0 10px 10px;
    color: #333333;
    font-size: 16px;
    line-height: 22px;
    box-sizing: border-box;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
  }
  ::v-deep .el-date-editor{
    &.data-date-picker{
      .el-input__inner{
        height: 40px;
        line-height: 40px;
        padding-left: 15px;
      }
    }
  }
}

.footer {
  @include flex;

  .btn {
    width: 160px;
    height: 40px;
    margin-top: 28px;
    font-size: 15px;
  }
}
.w-270{
  width: 270px;
}
</style>
